<template>
  <component
    v-if="actionName"
    :is="actionIconComponent"
    :class="className"
    :style="styles"
  />
</template>

<script>
import { getIconByAction } from '@/utils'

export default {
  name: 'OtoIcon',
  props: {
    actionName: {
      type: String,
      required: false,
    },

    path: {
      type: String,
      required: false,
    },

    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    className: {
      type: String,
      default: '',
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    actionIconComponent() {
      return getIconByAction(this.actionName)
    },
  },
}
</script>
